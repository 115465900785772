import { theme } from "@chakra-ui/core"
// src/gatsby-plugin-chakra-ui/theme.js
export default {
  ...theme,
  fonts: {
    body: "Mukta Malar, system-ui, sans-serif",
    heading: "Mukta Malar, Georgia, serif",
    mono: "Menlo, monospace",
  },
}
