module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"aatchithamizhiasacademy","accessToken":"MC5YNlR4UGhJQUFDQUFQSkMy.77-9NnxPMO-_vTYSQO-_vTrvv73vv73vv70477-9a0bvv73vv73vv73vv71uL--_vVTvv71Z77-977-977-9Xg","schemas":{"post":{"Main":{"uid":{"type":"UID","config":{"label":"url","placeholder":"URL"}},"main_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"main_image"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"Title","placeholder":"Title"}},"description":{"type":"StructuredText","config":{"single":"heading4","label":"description","placeholder":"Description"}},"date":{"type":"Date","config":{"label":"date","placeholder":"Date"}},"content":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"content","placeholder":"Content"}}},"Meta":{"categories":{"type":"Group","config":{"fields":{"category":{"type":"Link","config":{"select":"document","customtypes":["category"],"label":"category","placeholder":"Category"}}},"label":"Categories"}}}},"category":{"Main":{"name":{"type":"Text","config":{"label":"name","placeholder":"Name of the category"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142424170-6","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"968356360763485"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aatchi Tamizh IAS Academy","short_name":"Aatchi Tamizh","start_url":"/","background_color":"#2E3292","theme_color":"#2E3292","display":"standalone","icon":"images/shortLogo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"23711c3f1a1d3b7e93d9074d3b811710"},
    },{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[],"isUsingColorMode":false},
    }]
