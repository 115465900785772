// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bankexam-tsx": () => import("./../../../src/pages/bankexam.tsx" /* webpackChunkName: "component---src-pages-bankexam-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-group-2-and-4-admission-tsx": () => import("./../../../src/pages/group-2-and-4-admission.tsx" /* webpackChunkName: "component---src-pages-group-2-and-4-admission-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-otherexams-tsx": () => import("./../../../src/pages/otherexams.tsx" /* webpackChunkName: "component---src-pages-otherexams-tsx" */),
  "component---src-pages-physical-course-tsx": () => import("./../../../src/pages/physicalCourse.tsx" /* webpackChunkName: "component---src-pages-physical-course-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-railway-exam-tsx": () => import("./../../../src/pages/railwayExam.tsx" /* webpackChunkName: "component---src-pages-railway-exam-tsx" */),
  "component---src-pages-refund-policy-tsx": () => import("./../../../src/pages/refundPolicy.tsx" /* webpackChunkName: "component---src-pages-refund-policy-tsx" */),
  "component---src-pages-rtd-report-tsx": () => import("./../../../src/pages/rtd/report.tsx" /* webpackChunkName: "component---src-pages-rtd-report-tsx" */),
  "component---src-pages-special-training-tsx": () => import("./../../../src/pages/specialTraining.tsx" /* webpackChunkName: "component---src-pages-special-training-tsx" */),
  "component---src-pages-staff-selection-tsx": () => import("./../../../src/pages/staffSelection.tsx" /* webpackChunkName: "component---src-pages-staff-selection-tsx" */),
  "component---src-pages-teacher-exam-books-tsx": () => import("./../../../src/pages/teacherExamBooks.tsx" /* webpackChunkName: "component---src-pages-teacher-exam-books-tsx" */),
  "component---src-pages-teacher-exam-free-seminar-tsx": () => import("./../../../src/pages/teacherExamFreeSeminar.tsx" /* webpackChunkName: "component---src-pages-teacher-exam-free-seminar-tsx" */),
  "component---src-pages-teacher-exam-seminar-tsx": () => import("./../../../src/pages/teacherExamSeminar.tsx" /* webpackChunkName: "component---src-pages-teacher-exam-seminar-tsx" */),
  "component---src-pages-teacher-exam-training-tsx": () => import("./../../../src/pages/teacherExamTraining.tsx" /* webpackChunkName: "component---src-pages-teacher-exam-training-tsx" */),
  "component---src-pages-teacher-exam-tsx": () => import("./../../../src/pages/teacherExam.tsx" /* webpackChunkName: "component---src-pages-teacher-exam-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/termsAndConditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thank-you-register-tsx": () => import("./../../../src/pages/ThankYou/register.tsx" /* webpackChunkName: "component---src-pages-thank-you-register-tsx" */),
  "component---src-pages-thank-you-request-call-tsx": () => import("./../../../src/pages/ThankYou/requestCall.tsx" /* webpackChunkName: "component---src-pages-thank-you-request-call-tsx" */),
  "component---src-pages-tnpsc-2022-tsx": () => import("./../../../src/pages/tnpsc2022.tsx" /* webpackChunkName: "component---src-pages-tnpsc-2022-tsx" */),
  "component---src-pages-tnpsc-image-tsx": () => import("./../../../src/pages/tnpscImage.tsx" /* webpackChunkName: "component---src-pages-tnpsc-image-tsx" */),
  "component---src-pages-tnpsc-mock-test-tsx": () => import("./../../../src/pages/tnpscMockTest.tsx" /* webpackChunkName: "component---src-pages-tnpsc-mock-test-tsx" */),
  "component---src-pages-tnpsc-tsx": () => import("./../../../src/pages/tnpsc.tsx" /* webpackChunkName: "component---src-pages-tnpsc-tsx" */),
  "component---src-pages-tnpsccompetition-tsx": () => import("./../../../src/pages/tnpsccompetition.tsx" /* webpackChunkName: "component---src-pages-tnpsccompetition-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */),
  "component---src-pages-upsc-tsx": () => import("./../../../src/pages/upsc.tsx" /* webpackChunkName: "component---src-pages-upsc-tsx" */),
  "component---src-pages-whatsapp-tsx": () => import("./../../../src/pages/whatsapp.tsx" /* webpackChunkName: "component---src-pages-whatsapp-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

